<template>
  <div>
    <landingCover
      :coverImage="coverImage"
      :title="$t('transportation.headerTitle')"
      :subtitle="$t('transportation.headerSubtitle')"
      :routerName="checkIfLogin() ? 'registerTransportation' : 'login'"
      :btnTitle="$t('transportation.headerBtn')"
      routerAboutName="aboutTransportation"
    />
    <landing-details
      :img="landingDetailsImage"
      :details="$t('transportation.headerDetails')"
    />
    <landing-features :features="features" />
    <landing-how :items="items" />
    <landing-footer
        :routeName="checkIfLogin() ? 'registerTransportation' : 'login'"
        :btn-title="$t('transportation.headerBtn')"
      :title="$t('transportation.camelTransport')"
      :desc="$t('transportation.transportDesc')"
    />
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import landingCover from '../../mainComponentsLandingPage/landingCover.vue'
import landingDetails from '../../mainComponentsLandingPage/landingDetails.vue'
import landingFeatures from '../../mainComponentsLandingPage/landingFeatures.vue'
import landingHow from '../../mainComponentsLandingPage/landingHow.vue'
import landingFooter from '../../mainComponentsLandingPage/landingFooter.vue'
export default {
  name: 'transportation',
  components: {
    landingCover,
    landingDetails,
    landingFeatures,
    landingHow,
    landingFooter
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      coverImage: require('@/assets/images/ibbil/transportation/cover.jpg'),
      landingDetailsImage: require('@/assets/images/ibbil/transportation/about.jpg')
      // features: [
      //   {
      //     icon: 'img',
      //     title: this.$t('transportation.chooseFreelyTitle'),
      //     desc: this.$t('transportation.chooseFreelyDesc')
      //   },
      //   {
      //     icon: 'img',
      //     title: this.$t('transportation.targetAudienceTitle'),
      //     desc: this.$t('transportation.targetAudienceDesc')
      //   },
      //   {
      //     icon: 'img',
      //     title: this.$t('transportation.choosePriceTitle'),
      //     desc: this.$t('transportation.choosePriceDesc')
      //   }
      // ],
      // items: [
      //   {
      //     title: this.$t('transportation.HowTransport'),
      //     desc: this.$t('transportation.HowTransportDesc'),
      //     hasBtn: true,
      //     reverse: true,
      //     text: this.$t('transportation.headerBtn'),
      //     routeName: 'registerTransportation'
      //   }
      // ]
    }
  },
  computed: {
    features () {
      return [
        {
          icon: 'img',
          title: this.$t('transportation.chooseFreelyTitle'),
          desc: this.$t('transportation.chooseFreelyDesc')
        },
        {
          icon: 'img',
          title: this.$t('transportation.targetAudienceTitle'),
          desc: this.$t('transportation.targetAudienceDesc')
        },
        {
          icon: 'img',
          title: this.$t('transportation.choosePriceTitle'),
          desc: this.$t('transportation.choosePriceDesc')
        }
      ]
    },
    items () {
      return [
        {
          title: this.$t('transportation.HowTransport'),
          desc: this.$t('transportation.HowTransportDesc'),
          hasBtn: true,
          reverse: true,
          text: this.$t('transportation.headerBtn'),
          routeName: this.checkIfLogin() ? 'registerTransportation' : 'login'
        }
      ]
    }
  }
}
</script>
